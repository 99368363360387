.wrapper {
  padding: 97px 89px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  h1 {
    color: #030303;
    font-size: 42px;
    font-weight: 700;
    line-height: 70px;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  &__contact {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 40px;
      padding-left: 120px;
    }
  }
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .inputField,
  .textareaField {
    border: 1px solid #dadada;
    border-radius: 5px;
    height: 54px;
    width: 450px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    padding: 0px 8px;
    border-radius: 24px;
    background-color: #ffffff;
    color: black;
    font-size: 16px;
    font-family: 'Montserrat';
    font-weight: 500;
    line-height: 22px;
    outline: none;

    &::placeholder {
      color: black;
      vertical-align: middle;
      line-height: inherit;
    }
    @media screen and (max-width: 768px) {
      width: 350px;
      font-size: 12px;
    }
  }

  .textareaField {
    height: 104px;
    width: 450px;
    @media screen and (max-width: 768px) {
      width: 350px;
    }
  }

  .submitButton {
    cursor: pointer;
    width: 450px;
    height: 60px;
    border: 1px solid #030303;
    border-radius: 24px;
    background-color: #ffffff;
    color: #030303;
    @media screen and (max-width: 768px) {
      width: 350px;
      height: 50px;
      font-size: 14px;
    }
  }
}

.contactInfoContainer {
  max-width: 512px;
  width: 100%;
  height: 358px;
  background-color: #f0f0f0;
  border-radius: 24px;
  padding: 36px 32px;
  font-family: 'Montserrat';
  color: #030303;
  @media screen and (max-width: 768px) {
    // padding-right: 250px;
    width: 450px;
    margin-left: 1rem; /* The default value for px-4 in Tailwind is 1rem */
    height: 300px;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 22px;
    @media screen and (max-width: 768px) {
      margin-bottom: 12px;
      font-size: 18px;
    }
  }

  span {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    display: block;
    margin-bottom: 12px;
    @media screen and (max-width: 768px) {
      margin-bottom: 8px;
      font-size: 12px;
    }
  }

  p {
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 12px;
    @media screen and (max-width: 768px) {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  strong {
    text-decoration: underline;
  }
}

.contactSection {
  display: flex;
  justify-content: space-around;
  margin-bottom: 16px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
}

.heading {
  font-size: 60px;
  font-weight: 700;
  line-height: 70px;
  white-space: nowrap;
  margin-bottom: 70px;
  margin-left: 5.55vw;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
}
